<template>
  <AboutPage/>
</template>

<script>
import AboutPage from './components/AboutPage.vue'

export default {
  name: 'App',
  components: {
    AboutPage
  }
}
</script>

<style>
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: rgb(12, 12, 14);
  background-image: linear-gradient(295deg, rgb(2, 2, 4), rgb(13, 13, 22));
  color: white;
  font-family: "Roboto Mono", monospace;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: white;
}

p {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.863);
}

a {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: rgb(22, 219, 144);
}

.main {
  color: rgb(22, 219, 144);
}

.accent {
  color: rgb(255, 123, 0);
}
</style>
