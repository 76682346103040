<template>
  <div class="about">
    <img id="logo" src="../assets/icons/Q_Logo_2.png" />
    <div class="spacer"></div>
    <h2>
      Quantum is a simple, elegant, data-source agnostic coffee roasting
      software designed for professionals and hobbyists.
    </h2>
    <p>
      Our mindset behind Quantum comes from our DIY home-roasting roots,
      however, the software was developed in a production environment. Quantum
      can be run locally on a self-managed database, or you can utilize our
      infrastructure without worrying about setup.
    </p>
    <p>
      We've run nearly 15k roasts through Quantum at our own roastery so we've
      had plenty of time to modify and add features for quality of life and
      workflow improvements.
    </p>
    <div class="spacer"></div>
    <img src="../assets/Quantum_Graph_Layout_0.svg" />
    <div class="spacer"></div>
    <h2>Features</h2>
    <p>Data-source agnostic</p>
    <p>Record multiple profiles per coffee</p>
    <p>Batch size adjustment</p>
    <p>Roast curve prediction</p>
    <p>Per user-customizable settings</p>
    <p>User privileges</p>
    <p>Limitless roast history</p>
    <p>Browser based, mobile friendly</p>
    <p>Roaster control options</p>
    <p>Order/production run importing</p>
    <div class="spacer"></div>
    <h2>Documentation [ Coming Soon ]</h2>
    <div class="links">
      <div class="link">
        <!-- <a href="">Getting Started</a> -->
        Getting Started
      </div>
      <div class="link">
        <!-- <a href="">Data Collection</a> -->
        Data Collection
      </div>
      <div class="link">
        <!-- <a href="">Advanced Features</a> -->
        Advanced Features
      </div>
    </div>
    <div class="spacer"></div>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
  components: {
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.about {
  margin: 0 auto;
  padding: 0;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 840px) {
  .spacer {
  height: 1rem;
}
.links {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about {
  width: 100%;
}
}

@media screen and (min-width: 841px) {
  .spacer {
  height: 5rem;
}
.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.about {
  width: 60%;
}
}

.links a {
  margin: 0 1rem;
}

.link {
  text-decoration: line-through;
  color: rgb(22, 219, 144);
  margin: 5px;
}

#logo {
  min-width: 200px;
  max-width: 100%;
  margin: 0 auto;
}
</style>
